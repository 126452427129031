@font-face {
  font-family: 'Fira Sans Condensed';
  src: url('fonts/fira-sans/firasanscondensed-regular-webfont.woff2') format('woff2'),
       url('fonts/fira-sans/firasanscondensed-regular-webfont.woff') format('woff'),
       url('fonts/fira-sans/firasanscondensed-regular-webfont.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Fira Sans Condensed';
  src: url('fonts/fira-sans/firasanscondensed-medium-webfont.woff2') format('woff2'),
       url('fonts/fira-sans/firasanscondensed-medium-webfont.woff') format('woff'),
       url('fonts/fira-sans/firasanscondensed-medium-webfont.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
/*
@font-face {
  font-family: 'Fira Sans Condensed';
  src: url('fonts/fira-sans/firasanscondensed-lightitalic-webfont.woff2') format('woff2'),
       url('fonts/fira-sans/firasanscondensed-lightitalic-webfont.woff') format('woff'),
       url('fonts/fira-sans/firasanscondensed-lightitalic-webfont.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
}
*/
@font-face {
  font-family: 'Fira Sans Condensed';
  src: url('fonts/fira-sans/firasanscondensed-italic-webfont.woff2') format('woff2'),
       url('fonts/fira-sans/firasanscondensed-italic-webfont.woff') format('woff'),
       url('fonts/fira-sans/firasanscondensed-italic-webfont.ttf') format('truetype');
  font-weight: 400;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Fira Sans Condensed';
  src: url('fonts/fira-sans/firasanscondensed-mediumitalic-webfont.woff2') format('woff2'),
       url('fonts/fira-sans/firasanscondensed-mediumitalic-webfont.woff') format('woff'),
       url('fonts/fira-sans/firasanscondensed-mediumitalic-webfont.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}


*, *:after, *:before {
  box-sizing: border-box;
}

html {
  background-color: white;
  font-family: 'Fira Sans Condensed', Helvetica, sans-serif;
  font-weight: 400;
  line-height: 1.5;
  scroll-behavior: smooth;
}

body {
  min-height: 100vh;
  display: grid;
  grid-template-rows: auto 1fr auto;
}

strong, b {
  font-weight: 500;
}

em, i {
  font-style: italic;
}

a,
a:visited {
  color: inherit;
  text-decoration: none;
  opacity: 0.8;
}

p a {
  border-bottom: 1px dotted currentColor;
}

a:hover,
a:focus {
  opacity: 1;
}

a:focus {
  outline: 1px dotted currentColor;
  outline-offset: 1px;
  border: none;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: 500;
  margin: 0.5em 0;
  line-height: 1.3;
}

h1,
.site-title {
  font-size: 1.5em;
  font-weight: 500;
  color: rgba(0, 0, 0, 1);
  margin: 0.5em 0;
  line-height: 1.3;
}

h2 {
  font-size: 1.2em;
  margin-top: 1.5em;
}

h3 {
  font-size: 1.1em;
}

section h2,
.page-title {
  font-weight: 500;
  font-style: italic;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-bottom: 1em;

  &:after,
  &:before {
    content: '';
    flex-grow: 1;
    border-bottom: 1px solid;
    opacity: .2;
  }

  &:before {
    margin-right: 1em;
  }

  &:after {
    margin-left: 1em;
  }
}

h4, h5, h6 {
  font-size: 1em;
}

p {
  margin: 0.5em 0;
}

ul {
  padding: 0 1em;
  margin: 0.5em 0;
}

ul.list {
  list-style: none;

  > li {
    margin-bottom: .2em;
  }
}

blockquote {
  margin: 1em 0;
  font-family: monospace;
  font-weight: normal;
  font-size: 1.1em;
  line-height: 1.6;
  padding: .2em 1em;
  border-left: 3px solid rgba(0,0,0,.2);
  background: rgba(255,255,255,.4);
}

figure {
  margin: 0;
}

figcaption {
  hr {
    margin: .5em 0;
  }
}

img {
  display: block;
  max-width: 100%;
  height: auto;
}

.site-logo {
  display: inline-block;
}

hr {
  border: 0;
  color: inherit;
  border-bottom: 1px solid;
  opacity: .1;
  margin: 2em 0;
}

dl {
  display: grid;
  grid-column-gap: 1em;
  grid-template-columns: auto 1fr;
  align-items: baseline;
  margin: 0.5em 0;

  dd {
    margin-left: 0;
  }
}

input, textarea {
  color: rgba(0,0,0,0.9);
  padding: 6px;
  border: 0;
  border-radius: 0;
  display: block;
  width: 100%;
}

button,
input[type="submit"] {
  display: inline-block;
  border: 0;
  border-radius: 0;
  font-weight: 500;
  font-size: 1rem;
  background-color: rgba(114, 145, 158, 1);
  color: rgba(255, 255, 255, 1);
  padding: 0.5rem 2rem;
  transition: background-color 0.3s;
  width: auto;
}

button:hover,
input[type="submit"]:hover {
  background-color: rgba(53, 98, 117, 1);
}

nav ul {
  margin: 0;
  padding: 0;
  width: 100%;
  list-style: none;
}

nav li {
  display: inline-block;
}

nav a {
  display: block;
}

.content-wrap {
  padding: 2em 5vw;
  margin: 0 auto;
  max-width: 960px;
}

.site-header {
  text-align: center;
}

.site-nav {
  padding: .5em 5vw;
  font-size: 1em;
  font-weight: 500;
  line-height: 1.8;
  text-align: center;

  &.sticky {
    position: sticky;
    top: 0;
    z-index: 1001;
    box-shadow: 0px 4px 0 rgba(53, 98, 117, 0.1);
  }

  li {
    margin: 0 .5em;
  }
}

.site-footer {
  .content-wrap {
    max-width: none; 
  }
}

.page-meta {
  margin-bottom: 2em;
  font-style: italic;
  font-size: 0.9em;
  text-align: center;

  nav {
    display: inline-block;

    a {
      display: inline-block;
    }

    li {
      display: inline-block;
      margin-left: .5em;
    }
  }
}

header a {
  opacity: 1;
}

nav a {
  text-decoration: none;
}

nav a:hover {
  opacity: 1;
  text-decoration: underline;
}

.hidden {
  display: none;
} 

.no-margin {
  margin: 0;
}

.no-margin-top {
  margin-top: 0;
}

.no-list-style {
  list-style: none;
  padding: 0;
}

.neutralBg {
  background-color: rgba(53, 98, 117, 0.1);
  color: #000;
}

.neutralBg a,
.neutralBg a:visited,
.neutralBg h1 {
  color: rgba(53, 98, 117, 1);
}

.primaryBg {
  color: rgba(255, 255, 255, 1);
  background-color: rgba(53, 98, 117, 1);
}

.secondaryBg {
  color: rgba(255, 255, 255, 1);
  background-color: rgba(114, 145, 158, 1);
}

.sectionHero {
  margin-top: -4em;
}

#willkommen {
  position: relative;
}

#aktuell {
  margin-top: 2em;
  padding: .2em 1em;
  margin-bottom: -4em;
  margin-left: auto;
  margin-right: auto;
  border: 4px solid rgba(255, 255, 255, 0.1);
  box-shadow: 4px -4px 0 rgba(53, 98, 117, 0.1);
  max-width: 80ch;
  box-sizing: content-box;
}

#aktuell p {
  font-size: 0.9em;
  line-height: 1.4;
}

#aktuell h4 {
  border-bottom: 1px dashed rgba(255,255,255,.2);
  padding-bottom: .2em;
}

/* Compensate for negative margins in sectionHero */
#anwälte,
#kontakt {
  margin-top: -4em;
  padding-top: 4em;
}

#anwälte .content-wrap {
  display: flex;
  flex-direction: column-reverse;
}

.message-status:not(:target) {
  display: none;
}

.message-status:target ~ #contact-form,
#contact-form.disabled {
  display: none;
}

#map,
#map-og {
  width: 100%;
  height: 400px;
  background-color: rgba(53, 98, 117, 1);
}

.hero-section {
  background: url("../img/sozialrecht-fr-bild7.jpg") no-repeat center; 
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  padding-top: 40vh;
  color: rgba(53, 98, 117, 1);
}

.hero-section > div {
  background-color: rgba(255,255,255,0.85);
}

.hero-section > div,
#anwälte > div {
  padding-bottom: 3.2em;
}

.hero-section .heroImage {
  display: none;
  width: 100%;
}

.hero-text {
  max-width: 70ch;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 2em;
  font-size: 1.1em;
  line-height: 1.5;
  text-align: center;
  box-sizing: content-box;
}

.main-text {
  max-width: 80ch;
  margin-left: auto;
  margin-right: auto;
  box-sizing: content-box;
}

ul.labels li {
  display: flex;
  align-items: baseline;
}

ul.labels .label {
  margin-right: 0.5em;
}

ul.lawyers {
  color: rgba(53, 98, 117, 1);
  width: 100%;
  margin: 0.5em 0;
  text-transform: uppercase;
  list-style: none;
}

ul.lawyers > li {
  display: inline-block;
  margin: 0 0.6em;
}

.lawyerName {
  font-weight: 500;
  font-style: normal;
  margin-bottom: 0;
  padding: 0;
  white-space: nowrap;
}

.lawyerTitle {
  display: block;
  font-weight: 400;
  font-style: italic;
  font-size: 0.9em;
  opacity: 0.8;
  margin-bottom: 1em;
}

.lawyerSummary {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: baseline;
  /*position: relative;
  z-index: 9;*/
}

.lawyerSummary > * {
  flex: 1 1 150px;
  text-align: center;
  padding: .5em;
}

.lawyerSummary a {
  opacity: 1;
}

.lawyerSummary a:focus {
  outline: none;
}

.lawyerSummary img {
  max-width: 150px; 
  transition: background-color 0.3s;
  display: block;
  margin: 0 auto;
  box-shadow: 4px -4px 0 rgba(53, 98, 117, 0.1);
}

.lawyerSummary a:hover img,
.lawyerSummary a:focus img,
.lawyerSummary a:active img {
  background-color: rgba(53, 98, 117, 1);
}

.lawyerBio ul {
  list-style: square;
}

.lawyerBio ul li {
  /* Fix for bullets overlapping float item*/
  position: relative;;
  left: 1em;
}

.lawyerDetails {
  dl {
    display: block;
  }
}

.lawyerDetails > figure {
  float: left;
  margin: 0 2em 0 0;
  padding: 0 2em 1em;
}

.lawyerIntro {
  font-style: italic;
}

.lawyerImage {
  border: 4px solid rgba(255, 255, 255, 0.1);
  display: block;
  margin: -4px -4px 1em;
  box-sizing: content-box;
}

dt,
.label,
label {
  display: block;
  font-style: italic;
  font-weight: normal;
  font-size: 0.9em;
  opacity: 0.5;
  margin-top: 1em;
}

.form-group {
  margin: 1em 0;
}

.columns2 {
  -moz-columns:2 20em;
  -webkit-columns:2 20em;
  columns: 2 20em;
  -moz-column-gap: 2em;
  -webkit-column-gap: 2em;
  column-gap: 2em;
}

.columns2 > div {
  /*display: inline-block;
  width: 100%;*/
  overflow: hidden; /* Fix for collapsing margins */
}

.contact {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.contactInfo {
  order: 1;
}

.contactInfo .label {
  min-width: 5em;
}

.map {
  border: 4px solid rgba(255, 255, 255, 0.1);
  flex: 1 1 auto;
  order: 0;
}

.map-fr {
  margin-right: 3em;
}

footer nav {
  font-size: 0.9em;
}

footer nav ul {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

footer nav li {
  margin-right: 2em; 

  &:last-child {
    margin-right: 0;
  }
}

section footer {
  margin-top: 2em;
}

#footer {
  clear: both;
}

.impressum {
  margin: 2em 0;
}

.post-excerpt {
  font-weight: 500;
  line-height: 1.4;
}

.post-title {
  color: #356275;
  font-size: 1.3em;
  margin-bottom: 0;

  &.featured {
    font-size: 2em;
  }
}

h1.post-title,
h2.post-title {
  margin-bottom: .2em;
  font-size: 1.6em;
}

article.post {
  margin-bottom: 2em;
  display: grid;
  grid-template-columns: 7.5em minmax(auto, 70ch);
  grid-column-gap: 2em;
  align-items: baseline;
  grid-auto-flow: column;

  > * {
    grid-column: 2;
  }

  > .post-meta {
    grid-column: 1;
  }

  a {
    overflow-wrap: anywhere;
  }

  a,
  a:visited {
    color: #356275;
  }
}

.post:not(article) .post-excerpt {
  font-size: 1.1em;
}

.post-meta {
  font-style: italic;
  font-size: 0.9em;
}

.post-author {
  margin: 1em 0;
}

[itemprop="author"] {
  font-weight: 500;
  font-style: italic;

  &:before {
    content: '';
    background-image: url('/icons/favicon-16x16.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: .8em;
    width: .8em;
    height: .8em;
    display: inline-block;
    margin-right: .4em;
  }
}

.pagination {
  margin-top: 2em;

  a {
    color: #72919e;
  }
}

.sr-only {
    position:absolute;
    left:-10000px;
    top:auto;
    width:1px;
    height:1px;
    overflow:hidden;
}

@media screen and (max-width: 720px) {
  .contact {
    display: initial;
  }

  .map-fr {
    margin-right: 0;
  }

  .map-fr.sectionHero {
    margin-bottom: 0;
    margin-top: 2em;
  }

  .map-og.sectionHero {
    margin-top: -4em;
  }

  .lawyerSummary img {
    max-width: 120px; 
  }

  .lawyerDetails > figure {
    float: none;
    margin: 1em 0;
    padding: 0;
  }

  .lawyerBio ul li {
    position: initial;
  }

  .lawyerDetails > figure .lawyerImage {
    float: right;
    margin: 0 0 0.5em 1em;
    max-width: 150px;
  }

  #kontakt {
    margin-top: 0;
    padding-top: 0;
  }

  footer nav ul {
    justify-content: flex-start;
  }
}

@media screen and (max-width: 600px) {
  .hero-section {
    padding-top: 0;
    background: none;
  }

  .hero-section .heroImage {
    display: block;
  }

  .sectionHero {
    order: 1;
    margin-bottom: 0;
    margin-top: 2em;
  }

  article.post {
    display: block;
  }

  .post-title.featured {
    font-size: 1.6em;
  }

  #offene-sprechstunde > div,
  #offene-sprechstunde-og > div,
  #anwälte > div {
    padding-bottom: 0;
  }

  #anwälte {
    margin-top: 0;
    padding-top: 0;
  }

  #anwälte .content-wrap {
    display: flex;
    flex-direction: column;
  }
}

/*
@media screen and (max-width: 500px) {
  #offene-sprechstunde .content-wrap,
  #anwälte .content-wrap,
  #kontakt .content-wrap {
    padding-top: 3.5em;
  }

  #anwälte,
  #kontakt {
    margin-top: -6em;
    padding-top: 6em;
  }
}
*/

@media screen and (max-width: 400px) {
  .lawyerDetails > figure .lawyerImage {
    max-width: 80px;
    border-width: 2px;
  }
}

